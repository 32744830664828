.client-page {
	.client-img {
		width: 148px;
		height: 148px;

		img {
			width: 100%;
			height: 100%;
		}
	}
}