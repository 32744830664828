.award-page {
	.award-img {
		height: 195px;
		width: 141px;

		img {
			height: 100%;
			width: 100%;
		}
	}
}