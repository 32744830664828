@import './components';
@import './pages';
@import './event-section';

#root {
	height: 100%;
}

.form-login {
	padding: 200px 400px;
	background: #383a3d;
	height: 100%;

	.login-form {
		width: 450px;
		max-width: 450px;
		margin: auto;
		padding: 50px;
		background: #fff;


		.title {
			text-align: center;
			text-transform: uppercase;
		}

		.login-form-button {
			width: 100%;
		}
	}
}

.add-button {
	text-align: right;
	padding: 10px 0;

	button {
		min-width: 100px;

		&.ant-btn-primary:not(:last-child) {
			margin-right: 10px;
		}
	}
}

.edit-del-btn {
	.ant-btn-dangerous {
		margin-left: 10px;
	}
}

.site-content {
	// margin: 24px;
	// background-color: white;

	.site-layout-background {
		padding: 24px;
	}
}

.ck-content {
	min-height: 300px;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;

	div {
		position: absolute;
		top: 27px;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background: #aaa;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);

		&:first-child {
			left: 6px;
			animation: lds-ellipsis1 0.6s infinite;
		}

		&:nth-child(2) {
			left: 6px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(3) {
			left: 26px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(4) {
			left: 45px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
}

.ant-menu-item-selected {
	a {
		color: white;
	}
}

.edit-del-btn {
	text-align: right;
}

.custom-pagination {
	padding-top: 10px;
	text-align: right;
}

.swal2-container {

	.swal2-popup {
		width: 30em;
		padding: 1em;
	}

	#swal2-content {
		font-size: 20px;
	}

	.swal2-actions {
		.swal2-styled {
			padding: 5px 25px;
			border-radius: 2px;
		}
	}
}