.upload-box {
  width: 100%;
  text-align: center;
  margin: 0 auto;

  .box {
    font-size: 1.25rem;
    background-color: #c8dadf;
    position: relative;
    padding: 100px 20px;
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;

    .box-icon {
      width: 100%;
      height: 80px;
      fill: #92b0b3;
      display: block;
      margin-bottom: 40px;
    }

    label {
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
    }
  }
}

.image-box {
  margin: auto;
  background-color: white;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .btn-delete {
    position: absolute;
    right: 5px;
    top: 0;
    cursor: pointer;
    z-index: 99;
  }
}