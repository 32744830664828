#logo {
	position: relative;
	padding: 0 24px;
	overflow: hidden;
	background: #001529;
	cursor: pointer;
	transition: all .3s;

	a {
		display: grid;
		align-items: center;
		height: 64px;
		color: #fff;

		h1 {
			display: inline-block;
			margin: 0 0 0 5px;
			color: #fff;
			font-weight: 600;
			vertical-align: middle;
			animation: fade-in;
			animation-duration: .3s;
		}
	}
}