.add-edit-event-content-page {
	.ant-radio-group {
		padding: 10px 0;
	}

	.iframe-loader {
		margin-top: 20px;
		height: 500px;
	}

	.upload-box {
		.image-box {
			img {
				max-width: 100%;
				height: 100%;
				width: auto;
			}
		}
	}
}