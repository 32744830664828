.event-page {
	.content-table {
		.content-image {
			width: 285px;
			height: 160px;

			img {
				max-width: 100%;
				height: 100%;
				margin: auto;
			}
		}
	}
}