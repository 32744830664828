.ant-layout-header {
	padding: 0px;
	width: 100%;
	z-index: 9;
	background: #fff;

	.ant-pro-global-header {
		position: relative;
		display: flex;
		align-items: center;
		height: 64px;
		padding: 0;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

		.header-content {
			display: flex;
			height: 64px;
			margin-left: auto;
			overflow: hidden;
			align-items: center;
			padding: 0 20px;

			span {
				padding: 0 10px;
			}
		}
	}
}