.event-section {
	.add-button {
		text-align: right;
		padding: 10px 0;
	}
}

.event-section-detail {
	.ant-radio-group {
		padding: 10px 0;
	}
}